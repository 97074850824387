<template>
  <div>
    <div class="container">
      <!-- header -->
      <BaseHeader
        title="Plano estratégico"
        :navigation="navigation"
      ></BaseHeader>

      <div class="simulator mb-5" v-if="!loading">
        <div>
          <p class="title">Marketing</p>
          <div class="container-all-marketing">
            <!-- Investimento  -->
            <div class="container-marketing">
              <div class="container-label">
                <span id="investment" class="name">Investimento</span>
                <span
                  v-show="!show_investment"
                  id="investment"
                  class="value"
                  @click="
                    (show_investment = true), validShow('money-investment')
                  "
                  >R$ {{ investimento | formatMoney }}</span
                >
                <input
                  v-show="show_investment"
                  id="money-investment"
                  v-model="investimento"
                  class="reset-money"
                  @blur="show_investment = false"
                  @input="updateSlider2('investment', 50000)"
                  min="0"
                  max="50000"
                  type="number"
                />
              </div>
              <input
                ref="investment"
                id="custom-range"
                type="range"
                min="0"
                max="50000"
                data-max="50000"
                v-model="investimento"
                @input="updateSlider($event, 50000)"
              />
            </div>
            <!-- CPC -->
            <div class="container-marketing">
              <div class="container-label">
                <span id="investment" class="name">CPC</span>
                <span
                  v-show="!show_cpc"
                  id="investment"
                  class="value"
                  @click="(show_cpc = true), validShow('cpc')"
                  >R$ {{ cpc | formatMoney }}</span
                >
                <input
                  v-show="show_cpc"
                  id="clicks"
                  v-model="cpc"
                  class="reset-money"
                  step="0.01"
                  @blur="show_cpc = false"
                  @input="updateSlider2('clicks', 10)"
                  min="0"
                  max="10"
                  type="number"
                />
              </div>
              <input
                ref="clicks"
                id="custom-range"
                type="range"
                min="0"
                max="10"
                data-max="10"
                step="0.01"
                v-model="cpc"
                @input="updateSlider($event, 10)"
              />
            </div>
            <!-- Total Cliques -->
            <div class="container-marketing off">
              <div class="container-label">
                <span id="investment" class="off">Total Cliques</span>
                <span id="investment" class="off">{{
                  (investimento / cpc) | formatNumber
                }}</span>
              </div>
            </div>
            <!-- Taxa Clique por Lead -->
            <div class="container-marketing">
              <div class="container-label">
                <span id="investment" class="name">Taxa Clique por Lead</span>
                <span
                  v-show="!show_clicks"
                  id="investment"
                  class="value"
                  @click="(show_clicks = true), validShow('clicks')"
                  >{{ percentage_click }}%</span
                >
                <input
                  v-show="show_clicks"
                  id="clicks"
                  v-model="percentage_click"
                  class="reset-money"
                  step="1"
                  @blur="show_clicks = false"
                  @input="updateSlider2('clicks', 100)"
                  min="0"
                  max="100"
                  type="number"
                />
              </div>
              <input
                ref="clicks"
                id="custom-range"
                type="range"
                min="0"
                max="100"
                data-max="100"
                step="1"
                v-model="percentage_click"
                @input="updateSlider($event, 100)"
              />
            </div>
            <!-- CPL  -->
            <div class="container-marketing off">
              <div class="container-label">
                <span id="investment" class="off">CPL</span>
                <span id="investment" ref="cpl_price" class="off"
                  >R$
                  {{
                    (investimento /
                      ((investimento / cpc / 100) * percentage_click))
                      | formatMoney
                  }}</span
                >
              </div>
            </div>
            <!-- Total Leads  -->
            <div class="container-marketing off">
              <div class="container-label">
                <span id="investment" class="off">Total Leads</span>
                <span id="investment" class="off">{{
                  ((investimento / cpc / 100) * percentage_click) | formatNumber
                }}</span>
              </div>
            </div>
            <!-- Leads   -->
            <div class="container-marketing">
              <div class="container-label">
                <span id="investment" class="name">Leads </span>
                <span
                  v-show="!show_leads"
                  id="investment"
                  class="value"
                  @click="(show_leads = true), validShow('leads')"
                  >{{ leads }}%</span
                >
                <input
                  v-show="show_leads"
                  id="leads"
                  v-model="leads"
                  class="reset-money"
                  step="0.01"
                  @blur="show_leads = false"
                  @input="updateSlider2('leads', 1)"
                  min="0"
                  max="1"
                  type="number"
                />
              </div>
              <input
                ref="leads"
                id="custom-range"
                type="range"
                min="0"
                max="1"
                data-max="1"
                step="0.01"
                v-model="leads"
                @input="updateSlider($event, 1)"
              />
            </div>
            <!-- Quantidade de Vendas  -->
            <div class="container-marketing off">
              <div class="container-label">
                <span id="investment" class="off">Quantidade de Vendas</span>
                <span id="investment" class="off">{{
                  ((investimento / cpc / 100) * percentage_click * leads)
                    | formatNumber
                }}</span>
              </div>
            </div>
            <!-- CPV  -->
            <div class="container-marketing off">
              <div class="container-label">
                <span id="investment" class="off">CPV</span>
                <span id="investment" ref="cpv_price" class="off"
                  >R$
                  {{
                    (investimento /
                      ((investimento / cpc / 100) * percentage_click * leads))
                      | formatMoney
                  }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <div>
          <p class="title">Vendas</p>
          <div class="container-all-marketing">
            <!-- Venda Total  -->
            <div class="container-marketing total">
              <div class="container-label">
                <div style="display: flex; gap: 10px; align-items: center">
                  <img src="@/assets/img/icons/money.svg" />
                  <span id="investment" class="lucro">Lucro Total</span>
                </div>
                <span
                  id="investment"
                  class="value"
                  ref="totalProfit"
                  style="cursor: default"
                  >R$
                  {{
                    ((investimento / cpc / 100) *
                      percentage_click *
                      leads *
                      (venda - (venda / 100) * taxa_cartao) -
                      calculaCPV())
                      | formatMoney
                  }}</span
                >
              </div>
            </div>
            <!-- Valor Venda Bruta  -->
            <div class="container-marketing">
              <div class="container-label">
                <span id="investment" class="name">Valor Venda Bruta</span>
                <span
                  v-show="!show_sale"
                  id="investment"
                  class="value"
                  @click="(show_sale = true), validShow('sale')"
                  >R$ {{ venda | formatMoney }}</span
                >
                <input
                  v-show="show_sale"
                  id="sale"
                  v-model="venda"
                  class="reset-money"
                  @blur="show_sale = false"
                  @input="updateSlider2('sale2', 100000)"
                  min="0"
                  max="100000"
                  type="number"
                />
              </div>
              <input
                ref="sale2"
                id="custom-range"
                type="range"
                min="0"
                max="100000"
                data-max="100000"
                v-model="venda"
                @input="updateSlider($event, 100000)"
              />
            </div>
            <!-- Taxa de Cartão  -->
            <div class="container-marketing">
              <div class="container-label">
                <span id="investment" class="name">Taxa de Cartão</span>
                <span
                  v-show="!show_card"
                  id="investment"
                  class="value"
                  @click="(show_card = true), validShow('card')"
                  >{{ taxa_cartao }}%</span
                >
                <input
                  v-show="show_card"
                  id="card"
                  v-model="taxa_cartao"
                  class="reset-money"
                  step="0.01"
                  @blur="show_card = false"
                  @input="updateSlider2('card2', 10)"
                  min="0"
                  max="10"
                  type="number"
                />
              </div>
              <input
                ref="card2"
                id="custom-range"
                type="range"
                min="0"
                max="10"
                step="0.01"
                data-max="10"
                v-model="taxa_cartao"
                @input="updateSlider($event, 10)"
              />
            </div>
            <!-- Valor Líquido   -->
            <div class="container-marketing off">
              <div class="container-label">
                <span id="investment" class="off">Valor Líquido </span>
                <span id="investment" class="off"
                  >R$
                  {{
                    (venda - (venda / 100) * taxa_cartao) | formatMoney
                  }}</span
                >
              </div>
            </div>
            <!-- Total Vendas   -->
            <div class="container-marketing off">
              <div class="container-label">
                <span id="investment" class="off">Total Vendas </span>
                <span id="investment" class="off"
                  >R$
                  {{
                    ((investimento / cpc / 100) *
                      percentage_click *
                      leads *
                      (venda - (venda / 100) * taxa_cartao))
                      | formatMoney
                  }}</span
                >
              </div>
            </div>
            <!-- Custo produto/serviço + impostos  -->
            <div class="container-marketing">
              <div class="container-label">
                <span id="investment" class="name"
                  >Custo produto/serviço + impostos</span
                >
                <span
                  v-show="!show_poduction"
                  id="investment"
                  class="value"
                  @click="(show_poduction = true), validShow('poduction')"
                  >R$ {{ imposto_producao | formatMoney }}</span
                >
                <input
                  v-show="show_poduction"
                  id="poduction"
                  v-model="imposto_producao"
                  class="reset-money"
                  @blur="show_poduction = false"
                  @input="updateSlider2('poduction', 5000)"
                  min="0"
                  max="5000"
                  type="number"
                />
              </div>
              <input
                ref="poduction"
                id="custom-range"
                type="range"
                min="0"
                max="5000"
                data-max="5000"
                v-model="imposto_producao"
                @input="updateSlider($event, 5000)"
              />
            </div>
            <!-- Custo produto + CPV    -->
            <div class="container-marketing off">
              <div class="container-label">
                <span id="investment" class="off">Custo produto + CPV </span>
                <span id="investment" class="off"
                  >R$ {{ calculaCPV() | formatMoney }}</span
                >
              </div>
            </div>
            <BaseButton
              variant="primary"
              :disabled="loading"
              @click="saveSimulator"
              v-if="can_save"
            >
              Salvar plano de crescimento
            </BaseButton>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-center" v-if="loading">
        <b-spinner label="Loading..."></b-spinner>
      </div>

      <div class="validBtn"></div>
      <modalSimulator
        :simulator_data="simulator_data"
        :simulation_edit="simulation_edit"
      />
    </div>
  </div>
</template>

<script>
import BaseView from "@/template/BaseView.vue";
import { Money } from "v-money";
import modalSimulator from "@/components/Simulador/ModalSimulator";
//
import PlanService from "@/services/resources/PlanService";
const servicePlan = PlanService.build();
export default {
  components: {
    BaseView,
    Money,
    modalSimulator,
  },
  data() {
    return {
      navigation: [
        { link: "Estratégico", to: "/dynamicRoute/estrategico" },
        { link: "Gestão de plano", to: "/dynamicRoute/estrategico" },
        { link: "Plano estratégico", to: this.$route.fullPath },
      ],
      simulation_edit: {},
      // shows -->
      show_poduction: false,
      show_card: false,
      show_sale: false,
      show_leads: false,
      show_clicks: false,
      show_cpc: false,
      show_investment: false,
      // datas -->
      percentage_click: 50,
      cpc: 2.5,
      simulator_data: {},
      leads: 0.5,
      imposto_producao: 150,
      taxa_cartao: 5,
      venda: 1200,
      investimento: 5000,
      loading: false,
      client: {
        width: 0,
      },
      can_save: true,
    };
  },
  computed: {
    isMobile() {
      return this.client.width <= 768;
    },
  },
  filters: {
    formatMoney(number) {
      if (typeof number !== "undefined" && number && number != 0) {
        number = parseFloat(number);
        const formato = {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
          useGrouping: true,
        };
        return number.toLocaleString("pt-BR", formato);
      }
      return "0,00";
    },
    formatNumber(number) {
      if (typeof number !== "undefined" && number) {
        return number.toLocaleString("pt-BR", {
          maximumFractionDigits: 0,
          useGrouping: true,
        });
      }
      return "0";
    },
  },
  methods: {
    getSimulator(id) {
      servicePlan
        .read(id)
        .then((resp) => {
          this.simulation_edit = resp;
          // Cliques
          var cliques = resp.metas.find(
            (item) => item.meta_key == "cliques"
          ).meta_value;
          this.percentage_click = cliques;
          // Leads
          var leads = resp.metas.find(
            (item) => item.meta_key == "leads"
          ).meta_value;
          this.leads = leads;
          // Imposto Producao
          var imposto_producao = resp.metas.find(
            (item) => item.meta_key == "imposto_producao"
          ).meta_value;
          this.imposto_producao = imposto_producao;
          // Taxa Cartao
          var taxa_cartao = resp.metas.find(
            (item) => item.meta_key == "taxa_cartao"
          ).meta_value;
          this.taxa_cartao = taxa_cartao;
          // Taxa Cartao
          var taxa_cartao = resp.metas.find(
            (item) => item.meta_key == "taxa_cartao"
          ).meta_value;
          this.taxa_cartao = taxa_cartao;
          // Cpc
          var cpc = resp.metas.find(
            (item) => item.meta_key == "cpc"
          ).meta_value;
          this.cpc = cpc;
          // Venda
          var venda = resp.metas.find(
            (item) => item.meta_key == "venda"
          ).meta_value;
          this.venda = venda;
          // Investimento
          var investimento = resp.metas.find(
            (item) => item.meta_key == "investimento"
          ).meta_value;
          this.investimento = investimento;
          // Total Profit
          var total_profit = resp.metas.find(
            (item) => item.meta_key == "total_profit"
          ).meta_value;
          this.total_profit = total_profit;
          // Cpl Price
          var cpl = resp.metas.find(
            (item) => item.meta_key == "cpl"
          ).meta_value;
          this.cpl_price = cpl;
          // Cpv Price
          var cpv = resp.metas.find(
            (item) => item.meta_key == "cpv"
          ).meta_value;
          this.cpv_price = cpv;
        })
        .finally(() => {
          var inputs = document.querySelectorAll("#custom-range");
          for (let i = 0; i < inputs.length; i++) {
            const element = inputs[i];
            const max = element.dataset.max;
            const progress = (element.value / max) * 100;
            const gradient = `linear-gradient(to right, var(--greenn) ${progress}%, #E5E5E5 ${progress}%)`;
            element.style.background = gradient;
          }
          this.loading = false;
        });
    },
    updateSlider2(name, value) {
      var element = this.$refs[`${name}`];
      var rangeValue;
      if (name == "poduction") {
        rangeValue = this.imposto_producao;
      } else if (name == "card2") {
        rangeValue = this.taxa_cartao;
      } else if (name == "sale2") {
        rangeValue = this.venda;
      } else if (name == "leads") {
        rangeValue = this.leads;
      } else if (name == "clicks") {
        rangeValue = this.percentage_click;
      } else if (name == "cpc") {
        rangeValue = this.cpc;
      } else if (name == "investment") {
        rangeValue = this.investimento;
      }

      const progress = (rangeValue / value) * 100;
      const gradient = `linear-gradient(to right, var(--greenn) ${progress}%, #E5E5E5 ${progress}%)`;

      element.style.background = gradient;
    },
    canSave() {
      if (this.$store.getters.recursos.nstrategic) {
        this.can_save = true;
      }
    },
    updateSlider(event, value) {
      const rangeValue = event.target.value;
      const progress = (rangeValue / value) * 100;
      const gradient = `linear-gradient(to right, var(--greenn) ${progress}%, #E5E5E5 ${progress}%)`;

      event.target.style.background = gradient;
    },
    validShow(name) {
      var input = document.getElementById(`${name}`);
      this.$nextTick(() => input.focus());
    },
    calculaCPV() {
      var quatidade_vendas =
        (this.investimento / this.cpc / 100) *
        this.percentage_click *
        this.leads;

      return (
        quatidade_vendas * this.imposto_producao + parseFloat(this.investimento)
      );
    },
    saveSimulator() {
      var totalprofit = this.$refs.totalProfit.innerText.replace("R$ ", "");
      var cpl_price = this.$refs.cpl_price.innerText.replace("R$ ", "");
      var cpv_price = this.$refs.cpv_price.innerText.replace("R$ ", "");

      cpv_price = cpv_price.replace(".", "").replace(",", ".");
      cpl_price = cpl_price.replace(".", "").replace(",", ".");
      totalprofit = totalprofit
        .replace(".", "")
        .replace(".", "")
        .replace(".", "")
        .replace(",", ".");

      var data = {
        cliques: this.percentage_click,
        leads: this.leads,
        imposto_producao: this.imposto_producao,
        taxa_cartao: this.taxa_cartao,
        cpc: this.cpc,
        venda: this.venda,
        investimento: this.investimento,
        total_profit: totalprofit,
        cpl: cpl_price,
        cpv: cpv_price,
      };
      this.simulator_data = data;
      this.$bvModal.show("Modal-simulator");
    },
    handleResize() {
      this.client.width = window.innerWidth;
    },
  },
  mounted() {
    var inputs = document.querySelectorAll("#custom-range");
    for (let i = 0; i < inputs.length; i++) {
      const element = inputs[i];
      const max = element.dataset.max;
      const progress = (element.value / max) * 100;
      const gradient = `linear-gradient(to right, var(--greenn) ${progress}%, #E5E5E5 ${progress}%)`;
      element.style.background = gradient;
    }
    if (this.$route.params.id) {
      this.getSimulator(this.$route.params.id);
    }
  },
  created() {
    if (
      this.$store.getters.user.user.level != "commercial_leader" &&
      this.$store.getters.user.user.level != "admin" &&
      this.$store.getters.user.user.level != "owner"
    ) {
      this.$router.push("/dynamicRoute/AccessDenied");
    }
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.canSave();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
<style lang="scss" scoped>
.reset-money {
  width: 40% !important;
  margin-bottom: 0px !important;
  height: auto !important;
  padding: 5px 20px !important;
}
.total {
  background: var(--greenn2);
  .lucro {
    color: var(--greenn);
    font-size: 16px;
    font-weight: 600;
  }
}
.off {
  background: #f7f7f7;
}
.container-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .name {
    font-weight: 600;
    font-size: 16px;
  }
  .value {
    color: var(--greenn);
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
  }
  .off {
    color: #81858e;
    font-weight: 600;
    cursor: default;
  }
}
.container-marketing {
  display: grid;
  gap: 10px;
  // padding: 20px;
  height: 78px;
  padding: 0 20px;
  align-content: center;
  border-radius: 7px;
  border: 1px solid #e5e5e5;
}
.container-all-marketing {
  display: grid;
  gap: 15px;
}
.simulator {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  .title {
    font-size: 22px;
    font-weight: 600;
  }
}
input[type="range"] {
  border: none !important;
  padding: 0px !important;
  height: auto !important;
  /*   -webkit-appearance: none; */
  appearance: none !important;
  position: relative !important;
  width: 100% !important;
  cursor: pointer !important;
  height: 4px !important;
  border-radius: 15px !important;
  outline: none !important;
  margin: 0 !important;
}
//  Thumb: webkit
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  height: 10px !important;
  width: 10px !important;
  background-color: var(--greenn) !important;
  border-radius: 50% !important;
  border: 2px solid var(--greenn) !important;
  transition: 0.2s ease-in-out !important;
}
/* Thumb: Firefox */
input[type="range"]::-moz-range-thumb {
  height: 10px !important;
  width: 10px !important;
  background-color: var(--greenn) !important;
  border-radius: 50% !important;
  border: 2px solid var(--greenn) !important;
  transition: 0.2s ease-in-out !important;
}
</style>
