import Rest from "@/services/Rest";

/**
 * @typedef {CampaignService}
 */
export default class CampaignService extends Rest {
  /**
   * @type {String}
   */
  static resource = "campaign";
}
