import Rest from "@/services/Rest";

/**
 * @typedef {PipelineService}
 */
export default class PipelineService extends Rest {
  /**
   * @type {String}
   */
  static resource = "pipeline";
}
