<template>
  <GrModal
    name="Modal-simulator"
    id="Modal-simulator"
    idModal="Modal-simulator"
    ref="Modal-simulator"
    size="lg"
    title="Plano"
    @shown="fetchAll"
  >
    <div class="container-inputs">
      <div class="titleDate">
        <div class="mr-3">
          <label for="title">Titulo</label>
          <b-form-input
            id="title"
            name="title"
            type="text"
            v-validate="{ required: true, min: 3, max: 50 }"
            placeholder="Título do plano"
            v-model="title"
          />

          <b-form-invalid-feedback
            :state="!errors.has('title')"
            style="font-size: 13px"
          >
            {{
              title.length === 0
                ? "Por favor, insira título."
                : title.length < 3 || title.length > 40
                ? "Mínimo de 3 caracteres e máximo de 40 caracteres."
                : ""
            }}
          </b-form-invalid-feedback>
        </div>

        <div>
          <label for="data">Data</label>
          <date-range-picker
            ref="picker"
            :locale-data="localeDate"
            :showWeekNumbers="false"
            :showDropdowns="true"
            :autoApply="false"
            :ranges="dateRanges"
            v-model="dateRange1"
          >
            <template
              v-if="dateRange1.startDate && dateRange1.endDate"
              v-slot:input="picker"
              style="min-width: 350px"
            >
              <span class="date">{{ picker.startDate | date }}</span> -
              <span class="date">{{ picker.endDate | date }}</span>
            </template>
            <template
              v-else
              v-slot:input
              class="btn-table"
              title="Selecionar datas"
            >
              Selecione as datas
            </template>
          </date-range-picker>
        </div>
      </div>

      <div class="mt-4">
        <div class="add-link-body">
          <div class="label-flex">
            <label for="funis">Funis</label>
          </div>
          <router-link
            v-if="!campaigns.length && verificUserLevel"
            :to="{ name: 'CampanhasCriar' }"
            class="add-link link mb-0"
            >Deseja criar um funil?</router-link
          >
        </div>
        <BaseSelect
          id="funis"
          class="selectFunil"
          :selectModel="campaign"
          placeholder="Selecione um funil"
          trackname="title"
          track-by="id"
          :array="campaigns"
          @change="campaign = $event"
          :searchable="true"
          direction="top"
          :watch="true"
          :multiple="true"
          :hide-selected="true"
          selectLabel="Clique para selecionar"
          deselectLabel="Clique para remover"
          selectedLabel=""
          @search="debounceCampanhas($event)"
          :loading="loadingCampanhas"
          @callback="fetchCampanhas($event)"
          ><span slot="noResult">Nenhum funil encontrado.</span>
        </BaseSelect>
        
        <div class="add-link-body">
          <div class="label-flex">
            <label for="funis">Selecione uma fonte </label>
          </div>
        </div>

        <multiselect
          v-model="option_selected"
          placeholder="Selecione uma opção"
          label="title"
          track-by="id"
          :options="options"
          :searchable="false"
          :multiple="false"
          :hide-selected="true"
          selectLabel=""
          deselectLabel=""
          selectedLabel=""
          ><span slot="noResult">Nenhum funil encontrado.</span>
        </multiselect>

      

        <div v-if="selectPipeline == true">
          <div class="add-link-body">
          <div class="label-flex">
            <label for="funis">Selecione um CRM</label>
          </div>
        </div>
          <BaseSelect
          v-if="showComponentCrm"
          :selectModel="crm_selected"
          trackname="title"
          track-by="id"
          :array="crms"
          :searchable="true"
          :multiple="false"
          :hide-selected="true"
          placeholder="Selecione um funil"
          selectLabel=""
          deselectLabel=""
          selectedLabel=""
          @callback="fetchCRMS($event)"
          @select="crm_selected = $event"
          @search="debounceCrm($event)"
          :watch="true"
          ><span slot="noResult">Nenhum funil encontrado.</span>
          </BaseSelect>
        </div>

        <!-- <BaseSelect
          :selectModel="selected_funnels"
          placeholder="Escolha os funis"
          label="Funis"
          trackname="title"
          track-by="id"
          :array="funnels"
          :multiple="true"
          :taggable="true"
          class=""
          open-direction="bottom"
          selectLabel=""
          deselectLabel=""
          selectedLabel=""
          @callback="getFunnels($event)"
          @change="selected_funnels = $event"
          :timeout="500"
          ><span slot="noResult"
            >Nenhuma permissão encontrada.</span
          ></BaseSelect
        > -->
      </div>
    </div>
    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        Fechar
      </BaseButton>
      <BaseButton variant="primary" :disabled="loading" @click="onsubmit">
        Salvar
      </BaseButton>
    </template>

    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </GrModal>
</template>

<script>
import moment from "moment-timezone";
import DateRangePicker from "vue2-daterange-picker";
import Multiselect from "vue-multiselect";
//
import CampaignService from "@/services/resources/CampaignService";
const serviceCampaign = CampaignService.build();

import PipelineService from "@/services/resources/PipelineService";
const servicePipelines = PipelineService.build();
//
import PlanService from "@/services/resources/PlanService";
const servicePlan = PlanService.build();

import _ from "lodash";

export default {
  props: ["simulator_data", "simulation_edit", "isMobile"],
  components: {
    Multiselect,
    DateRangePicker,
  },
  data() {
    return {
      stop: false,
      loading: false,
      crms:[],
      crm_selected: null,
      option_selected: {id:true, title:'CRM'},
      selectPipeline: true,
      title: "",
      campaigns: [],
      options:[{id:false, title:'Greenn - Em breve', $isDisabled: true},{id:true, title:'CRM'}],
      campaign: [],
      operation_date: 0,
      localeDate: {
        direction: "ltr",
        format: "dd/mm/yyyy",
        separator: " - ",
        applyLabel: "Aplicar",
        cancelLabel: "Cancelar",
        weekLabel: "W",
        customRangeLabel: "Custom Range",
        daysOfWeek: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
        monthNames: [
          "Jan",
          "Fev",
          "Mar",
          "Abr",
          "Mai",
          "Jun",
          "Jul",
          "Ago",
          "Set",
          "Out",
          "Nov",
          "Dez",
        ],
        firstDay: 0,
      },
      dateRange1: {
        startDate: moment(new Date(2015, 0, 1)),
        endDate: moment(),
      },
      stopCampanhas: "",
      loadingCampanhas: false,
      searchCampanhas: "",
      pageCampanhas: 1,
      stop_crm: false,
      searchCrm: "",
      pageCrm: 0,
      showComponentCrm: true
    };
  },
  computed: {
    verificUserLevel() {
      var user = this.$store.getters.user.user.level;
      if (user === "owner" || user === "admin" || user == "gerent_seller") {
        return true;
      } else {
        return false;
      }
    },
    dateRanges() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      let nowtoday = new Date();
      nowtoday.getDay() + 1;

      let yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);

      let lastWeek = new Date();
      lastWeek.setDate(today.getDate() - 7);
      lastWeek.setHours(0, 0, 0, 0);

      return {
        Hoje: [today, nowtoday],
        // Ontem: [yesterday, yesterday],
        "Última semana": [lastWeek, today],
        "Este mês": [
          new Date(today.getFullYear(), today.getMonth(), 1),
          new Date(today.getFullYear(), today.getMonth(), 30),
        ],
        "Este ano": [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31),
        ],
        "Último mês": [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0),
        ],
        "Todo período": [
          new Date(2015, 0, 1),
          new Date(today.getFullYear(), today.getMonth(), today.getDate()),
        ],
      };
    },
  },
  methods: {
    setPipeline(data){
      this.pipeline_selected = data.id;
    },
    selectOption(data){
      if(data.id){
        this.selectPipeline = true;
      }else{
        this.selectPipeline = false;
        this.crm_selected = null;
      }
    },  
    reset() {
      this.title = "";
      this.dateRange1 = {
        startDate: moment(new Date(2015, 0, 1)),
        endDate: moment(),
      };
    },
    onsubmit() {
      this.$validator.validate().then((result) => {
        if (result) {
          if (this.title.length === 0) {
            this.$grToast.toast("Titulo é necessário", {
              title: "Planos",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            return;
          }
          if (!this.campaign.length) {
            this.$grToast.toast("Selecione pelo menos um funil", {
              title: "Planos",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            return;
          }
           if (this.selectPipeline && !this.crm_selected) {
            this.$grToast.toast("Selecione um CRM", {
              title: "Planos",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            return;
          }
          const ids = this.campaign.map(obj => obj.id);
          var data = {
            title: this.title,
            campaigns: ids,
            start_date: this.dateRange1.startDate,
            end_date: this.dateRange1.endDate,
            metas: this.simulator_data,
            pipeline_id: this.crm_selected && this.crm_selected.id ? this.crm_selected.id : null
          };
          if (!this.$route.params.id) {
            servicePlan
              .create(data)
              .then((resp) => {
                this.loading = true;
                this.$grToast.toast("Plano criado com sucesso", {
                  title: "Plano",
                  variant: "success",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
                this.reset();
                this.$grModal.hide("Modal-simulator", this);
                this.$router.push({ name: "PlanoLista" });
              })
              .catch((error) => {
                this.$grToast.toast("Erro ao criar plano", {
                  title: "Plano",
                  variant: "danger",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
              })
              .finally(() => {
                this.loading = false;
              });
          }
          if (this.$route.params.id) {
            data.id = this.$route.params.id;
            servicePlan
              .update(data)
              .then((resp) => {
                this.loading = true;
                this.$grToast.toast("Plano atualizado com sucesso", {
                  title: "Plano",
                  variant: "success",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
                this.reset();
                this.$grModal.hide("Modal-simulator", this);
                this.$router.push({ name: "PlanoLista" });
              })
              .catch((error) => {
                this.$grToast.toast("Erro ao atualizar plano", {
                  title: "Plano",
                  variant: "danger",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
              })
              .finally(() => {
                this.loading = false;
              });
          }
        }
        });
  },

  createPlan(data) {
    servicePlan
      .create(data)
      .then((resp) => {
        this.loading = true;
        this.$grToast.toast("Plano criado com sucesso", {
          title: "Plano",
          variant: "success",
          autoHideDelay: 5000,
          appendToast: true,
        });
        this.reset();
        this.$grModal.hide("Modal-simulator", this);
        this.$router.push({ name: "PlanoLista" });
      })
      .catch((error) => {
        this.$grToast.toast("Erro ao criar plano", {
          title: "Plano",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
      })
      .finally(() => {
        this.loading = false;
      });
  },

  updatePlan(data) {
    data.id = this.$route.params.id;
    servicePlan
      .update(data)
      .then((resp) => {
        this.loading = true;
        this.$grToast.toast("Plano atualizado com sucesso", {
          title: "Plano",
          variant: "success",
          autoHideDelay: 5000,
          appendToast: true,
        });
        this.reset();
        this.$grModal.hide("Modal-simulator", this);
        this.$router.push({ name: "PlanoLista" });
      })
      .catch((error) => {
        this.$grToast.toast("Erro ao atualizar plano", {
          title: "Plano",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
      })
      .finally(() => {
        this.loading = false;
      });
  },
  fetchAll() {
      if (this.$route.params.id) {
        this.title = this.simulation_edit.title;
        this.dateRange1.startDate = this.simulation_edit.start_date;
        this.dateRange1.endDate = this.simulation_edit.end_date;
        this.campaign = this.simulation_edit.campaigns
        
        if(!this.simulation_edit.pipeline){
          this.option_selected = {id:false, title:'Greenn'};
          return;
        }

        this.selectPipeline = true;
        this.option_selected = {id:true, title:'CRM'};
        this.crm_selected = this.simulation_edit.pipeline;
        return;
      }
      this.title = "";
      this.option_selected = {id:true, title:'CRM'};
      this.crm_selected = null;
      this.campaign = [];
    },
    debounceCampanhas: _.debounce(function (query) {
      if (query === "") {
        this.loadingCampanhas = false;
      } else {
        this.searchCampanhas = query;
        this.fetchCampanhas(this.pageCampanhas, query);
      }
    }, 500),
    fetchCampanhas(page = 1, query = "") {
      if (this.stopCampanhas == true) {
        return;
      }

      serviceCampaign
        .read(`?page=${page}&order_by=name&order=ASC&search=${query}`)
        .then((resp) => {
          this.loadingCampanhas = false;
          if (resp.data.length < resp.per_page && query == "") {
            this.stopCampanhas = true;
          }
          this.campaigns = this.campaigns.concat(resp.data);
          this.campaigns = this.campaigns.filter(
            (item, index, opt) =>
              index === opt.findIndex((t) => t.id === item.id)
          );
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loadingCampanhas = false;
        });
    },
    fetchCRMS(page = 1, query = "") {
      if (this.stop_crm) {
        return;
      }

      var data = {
        page: `${page}&order_by=title&order=ASC&search=${query}`,
      };

      servicePipelines
        .search(data)
        .then((response) => {
          var crms = response.data.map((element) => ({
            id: element.id,
            title: element.title,
            tags: element.tags,
          }));

          if (response.data.length < response.per_page) {
            this.stop_crm = true;
          }

          this.crms = this.crms.concat(crms);
          this.crms = this.crms.filter(
            (item, index, opt) =>
              index === opt.findIndex((t) => t.id === item.id)
          );
        })
    },
    resetComponentCrm() {
      this.pageCrm = 1;
      this.searchCrm = 0;
      this.crms = [];
      this.stop_crm = false;
      this.showComponentCrm = false;
      this.$nextTick(() => {
        this.showComponentCrm = true;
      });
    },
    debounceCrm: _.debounce(function (query) {
      if (query === "") {
        this.resetComponentCrm();
      } else {
        this.searchCrm = query;
        this.pageCrm = 1;
        this.crms = [];
        this.stop_crm = false;
        this.fetchCRMS(this.pageCrm, query);
      }
    }, 500),
  },
};
</script>

<style scoped lang="scss">
.label-flex{
  padding-top: 10px;
}
.multiselect__content-wrapper {
  background-color: blue !important;
}
.container-inputs {
  display: grid;
  height: 100%;
  gap: 10px;

  .titleDate {
    display: flex;
    // gap: 10px;
    // justify-content: space-between;

    input {
      width: 100%;
    }
    @media (max-width: 768px) {
      input {
        width: 170px;
      }
      .date {
        font-size: 13px !important;
      }
    }
  }
}
</style>
